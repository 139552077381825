import React from 'react';
import ReactTexty from '../../lib/Texty';

import Typography, { ITypographyProps } from '../../atomic-design-components/atoms/Typography/Typography';
import Icon from '../../atomic-design-components/atoms/Icon/Icon';

const NameWithLogo = ({
	iconName,
	iconSize,
	name,
	typographyVariant,
}: {
	iconName: string;
	iconSize?: number;
	name: string;
	typographyVariant?: ITypographyProps['variant']
}) => (
	<>
		{name && (
			<Icon
				name={iconName}
				size={iconSize || 20}
			/>
		)}
		<Typography
			component="div"
			variant={typographyVariant || 'button1'}
			margin="0 0 0 6px"
			className="name"
			as={ReactTexty}
			textTransform="capitalize"
		>
			{name || '-'}
		</Typography>

	</>
);

export default NameWithLogo;