import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';

import { useTranslation } from 'react-i18next';
import { WEBSOCKET_URL_START } from '../api';
import { EVENT_TYPE_TO_CHAT_STATUS } from '../constants';
import {
	verifyToken,
} from '../redux-saga/actions';
import {
	selectIsUserOnline,
	selectUserMemberId,
	selectUserToken,
} from '../redux-saga/selectors';
import { getMessageData, playSound, showPushNotification } from '../utils/chat';
import { EnumChatEventType, handleMessageFromWebsocket } from '../ducks/chats';

export const useWebsocketHandler = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation('popupAlerts');

	const userToken = useSelector(selectUserToken);
	const isUserOnline = useSelector(selectIsUserOnline);
	const userId = useSelector(selectUserMemberId);

	const {
		lastJsonMessage,
		lastMessage,
	} = useWebSocket(
		`${WEBSOCKET_URL_START}/chat/operator?token=${userToken}`,
		{
			share: true,
			shouldReconnect() {
				dispatch(verifyToken());

				return isUserOnline;
			},
			reconnectInterval: 5000,
			reconnectAttempts: 50,
		},
		isUserOnline,
	);

	useEffect(() => {
		const messageData = getMessageData(lastJsonMessage);

		if (
			!messageData
			|| messageData?.status === EnumChatEventType.operator_connected
		) {
			return;
		}

		if (
			document.activeElement?.id !== `chatTextarea-${messageData?.chat_id}`
			&& messageData?.type
			&& EVENT_TYPE_TO_CHAT_STATUS.includes(messageData.type)
			&& messageData?.type !== EnumChatEventType.contact_typing
		) {
			playSound(messageData, userId);
			showPushNotification(messageData, t);
		}

		handleMessageFromWebsocket(messageData);
	}, [lastMessage]);
};

export const useWebsocketRequest = () => {
	const dispatch = useDispatch();

	const userToken = useSelector(selectUserToken);
	const isUserOnline = useSelector(selectIsUserOnline);

	const {
		sendMessage,
		sendJsonMessage,
		lastJsonMessage,
		lastMessage,
	} = useWebSocket(
		`${WEBSOCKET_URL_START}/chat/operator?token=${userToken}`,
		{
			share: true,
			shouldReconnect() {
				dispatch(verifyToken());

				return isUserOnline;
			},
			reconnectInterval: 5000,
			reconnectAttempts: 50,
		},
		isUserOnline,
	);

	return ({
		sendMessage,
		sendJsonMessage,
		lastJsonMessage,
		lastMessage,
	});
};