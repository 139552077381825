import {
	call, put, select, takeLatest,
} from 'redux-saga/effects';

import { buildRequestGenerator, fetchData, showError } from '../../api';
import { MY_COMPANY } from '../../config';
import {
	getCurrentSubscription, getTableData, popupAlertShow,
} from '../actions';
import {
	DELETE_ENTITY,
	GET_CURRENT_USER_SUCCESS,
	GET_INVITATION_LINK,
	GET_INVITATION_LINK_ERROR,
	GET_INVITATION_LINK_SUCCESS,
	GET_TABLE_ITEM_DETAILS,
	GET_TABLE_ITEM_DETAILS_ERROR,
	GET_TABLE_ITEM_DETAILS_SUCCESS,
	INVITATIONS_RESEND,
	INVITATIONS_RESEND_SUCCESS,
	INVITATIONS_SEND,
	INVITATIONS_SEND_SUCCESS,
	SIDEBAR_UPSERT,
	SIDEBAR_UPSERT_ERROR,
	SIDEBAR_UPSERT_SUCCESS,
	UPDATE_CURRENT_PLAN,
	UPDATE_CURRENT_PLAN_ERROR,
} from '../constants';
import {
	selectCurrentSubscription,
	selectMainData,
	selectSidebarDetails,
	selectUserDetails,
} from '../selectors';
import { doUpdateTableData } from './data';
import { doGetDepartmentMembersAvatars, doGetFileUrl } from './fileUpload';
import { ApiUrlType, IApiSchema, TNavigate } from '../../types';
import { globalHandleError } from '../../utils/globalHandleError';
import { actionsChats } from '../../ducks/chats';

function* doGetTableItemDetails({ payload: { type, id } }: {
	payload: {
		type: ApiUrlType;
		id: number;
	}
}) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type,
			id,
		});
		const response: IApiSchema['LimitOffsetPage_CustomerBrief_'] = yield fetchData(request);

		yield showError(response);

		let itemToSet;

		if (type === 'members' || type === 'customers') {
			// @ts-ignore
			itemToSet = yield doGetFileUrl(
				// @ts-ignore
				response,
				type === 'members' ? 'profile_picture' : 'owner_profile_picture',
				// @ts-ignore
				response[type === 'members' ? 'profile_picture' : 'owner_profile_picture'],
			);
		} else {
			itemToSet = response;
		}
		yield put({
			type: GET_TABLE_ITEM_DETAILS_SUCCESS,
			item: itemToSet,
		});
	} catch (error) {
		yield put({ type: GET_TABLE_ITEM_DETAILS_ERROR });

		globalHandleError({
			module: 'sidebar',
			subModule: 'doGetTableItemDetails',
			error,
		});
	}
}

function* doUpsertSidebar({ payload }: {
	payload: {
		apiUrlParam: string;
		id: number;
		type: ApiUrlType;
		requestBody: {
			channels?: IApiSchema['IChannels'];
			state: string;
			customer_id: string;
			full_name: string;
			tags: string[];
			customer_tags: string[];
		};
		navigate: TNavigate;
		location: Location;
		successContentKey: string;
	}
}) {
	const {
		apiUrlParam, id, type, requestBody, navigate, location, successContentKey,
	} = payload;

	if (type === 'widgets') {
		if (requestBody?.channels?.facebook?.accessToken) {
			requestBody.channels.facebook.access_token = requestBody.channels.facebook.accessToken;
		}

		if (requestBody?.channels?.instagram?.accessToken) {
			requestBody.channels.instagram.access_token = requestBody.channels.instagram.accessToken;
		}
	}

	if (requestBody?.channels?.facebook?.status === 'inactive') {
		Object.keys(requestBody.channels.facebook).forEach((key) => {
			if (key !== 'status') {
				// @ts-ignore
				requestBody.channels.facebook[key] = '';
			}
		});
	}

	if (requestBody?.channels?.instagram?.status === 'inactive') {
		Object.keys(requestBody.channels.instagram).forEach((key) => {
			if (key !== 'status') {
				// @ts-ignore
				requestBody.channels.instagram[key] = '';
			}
		});
	}

	const isDeleteOperation = requestBody.state === 'deleted';

	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: id ? 'PATCH' : 'POST',
			type,
			id,
			requestBody,
			apiUrlParam,
		});

		const response: unknown = yield fetchData(request);

		yield showError(response);

		// @ts-ignore
		const itemToSet = response?.value || response;
		let newItem: {
			id?: string;
			full_name?: string;
			status?: string;
			profile_picture?: string;
		} = {};

		if (type === 'members') {
			if (itemToSet.profile_picture.includes('http')) {
				newItem = itemToSet;
			} else {
				newItem = yield call(doGetFileUrl, itemToSet, 'profile_picture', itemToSet.profile_picture);
			}
		}

		if (itemToSet) {
			yield put({ type: SIDEBAR_UPSERT_SUCCESS, item: { ...itemToSet, ...newItem } });

			if (itemToSet.status) {
				const planData: IApiSchema['CurrentCompany'] = yield select(selectCurrentSubscription);

				// @ts-ignore
				if (planData && (Object.keys(planData?.counts).includes(type) || type === 'members')) {
					yield put(getCurrentSubscription());
				}
			}

			if (type === 'departments') {
				const departments: unknown[] = yield doGetDepartmentMembersAvatars(
					{ departments: [itemToSet] },
				);

				yield doUpdateTableData(type, departments?.[0] || itemToSet, isDeleteOperation, !id);
			} else if (type === 'members') {
				yield doUpdateTableData(type, { ...itemToSet, ...newItem }, isDeleteOperation, !id);

				if (location?.pathname?.includes('my-profile')) {
					const currentUser: IApiSchema['CurrentUser'] = yield select(selectUserDetails);

					yield put({
						type: GET_CURRENT_USER_SUCCESS,
						payload: {
							...currentUser,
							...newItem,
							id: currentUser.id,
							email_verified: currentUser.email_verified,
							member_id: newItem.id || currentUser.member_id,
							member_full_name: newItem.full_name || currentUser.member_full_name,
							member_status: newItem.status || currentUser.member_status,
							member_profile_picture: newItem.profile_picture,
						},
					});
				}
			} else if (['knowledgeBaseFolder', 'knowledgeBaseArticle'].includes(type)) {
				yield doUpdateTableData(
					'knowledgeBase',
					itemToSet,
					isDeleteOperation,
					!id,
					type === 'knowledgeBaseFolder' ? 'knowledgeBaseFolder' : 'articles',
				);
			} else {
				yield doUpdateTableData(
					type,
					itemToSet,
					isDeleteOperation,
					!id && !(type === 'customers' && requestBody.customer_id),
				);
			}

			if (requestBody.tags) {
				yield put(getTableData({ type: 'tags', isSystemData: true }));
			}
			if (requestBody.customer_tags) {
				yield put(getTableData({ type: 'customer_tags', isSystemData: true }));
			}
		}

		if (location?.pathname.includes('chats') && type === 'contacts' && typeof requestBody.full_name !== 'undefined') {
			yield put(
				actionsChats.setContactNameToChat({
					chat_id: itemToSet.chat_id,
					contact_name: itemToSet.full_name,
				}),
			);
		}

		if (isDeleteOperation) {
			navigate({ pathname: '..', search: location?.search });
		}

		const DATA_TYPE_TO_URL_TYPE = {
			widgets: 'chat-widgets',
			bulkMessaging: 'bulk-messaging',
			subscriptionPlans: 'subscription-plans',
		};

		const parentType = MY_COMPANY.some((object) => object.key === type) ? 'my-company' : '';
		// @ts-ignore
		const urlType = DATA_TYPE_TO_URL_TYPE[type] || type;

		if (!id && navigate && itemToSet.id) {
			const parentTypePrefix = parentType ? `${parentType}/` : '';
			const url = `${parentTypePrefix}${urlType}/${itemToSet.id}`;
			navigate(`/${url}`);
		}

		yield put(
			popupAlertShow({
				contentKey: successContentKey || 'dataSuccessfullySaved',
				type: 'success',
				withCloseButton: true,
				iconName: 'statusActive',
			}),
		);
	} catch (error) {
		yield put({ type: SIDEBAR_UPSERT_ERROR, error });

		globalHandleError({
			module: 'sidebar',
			subModule: 'doUpsertSidebar',
			error,
		});
	}
}

function* doDeleteEntity({ payload }: {
	payload: {
		id: number;
		type: ApiUrlType;
		navigate: TNavigate;
		location: Location;
		successContentKey: string;
		navigateTo: string;
	}
}) {
	const {
		id, type, navigate, location, successContentKey, navigateTo,
	} = payload;

	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'DELETE',
			type,
			id,
		});

		const response: unknown = yield fetchData(request);

		yield showError(response);

		const itemToSet = { id };
		const knowledgeBase = ['knowledgeBaseFolder', 'knowledgeBaseArticle', 'knowledgeBase'].includes(type) ? type : '';

		// @ts-ignore
		yield doUpdateTableData(type, itemToSet, true, !id, knowledgeBase);

		const planData: IApiSchema['CurrentCompany'] = yield select(selectCurrentSubscription);

		// @ts-ignore
		if (planData && (Object.keys(planData?.counts).includes(type) || type === 'members')) {
			yield put(getCurrentSubscription());
		}

		navigate({ pathname: navigateTo || '..', search: location.search });

		yield put(
			popupAlertShow({
				contentKey: successContentKey || 'dataSuccessfullySaved',
				type: 'success',
				withCloseButton: true,
				iconName: 'statusActive',
			}),
		);
	} catch (error) {
		yield put({ type: SIDEBAR_UPSERT_ERROR, error });

		globalHandleError({
			module: 'sidebar',
			subModule: 'doDeleteEntity',
			error,
		});
	}
}

function* doSendInvitations({ requestBody }: {
	requestBody: {
		email: string;
		full_name: string;
		role: string;
		customer_id: string;
	}
}) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'invite',
			requestBody,
		});

		const response: IApiSchema['MemberBrief'] = yield fetchData(request);

		if (response === null) {
			yield put(
				popupAlertShow({
					contentKey: 'Member already exists',
					type: 'error',
					timeout: 10000,
					withCloseButton: true,
					iconName: 'statusDeleted',
				}),
			);

			throw new Error('Member already exists');
		}

		yield showError(response);

		yield put({
			type: INVITATIONS_SEND_SUCCESS,
		});

		yield put(
			popupAlertShow({
				contentKey: 'invitationSent',
				type: 'success',
				withCloseButton: true,
				iconName: 'statusActive',
			}),
		);

		// @ts-ignore
		const itemToSet = response?.value || response;

		const getLink: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'inviteLink',
			apiUrlParam: itemToSet.id,
		});

		yield fetchData(getLink);

		yield doUpdateTableData('members', itemToSet, false, true);
	} catch (error) {
		yield put({ type: SIDEBAR_UPSERT_ERROR, error });

		globalHandleError({
			module: 'sidebar',
			subModule: 'doSendInvitations',
			error,
		});
	}
}

function* doResendInvitations({ id }: { id: string }) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'inviteResend',
			apiUrlParam: id,
		});

		const response: IApiSchema['MemberInviteUrl'] = yield fetchData(request);

		yield showError(response);

		yield put({
			type: INVITATIONS_RESEND_SUCCESS,
		});

		yield put(
			popupAlertShow({
				contentKey: 'invitationSent',
				type: 'success',
				withCloseButton: true,
				iconName: 'statusActive',
			}),
		);

		const getLink: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'inviteLink',
			apiUrlParam: id,
		});

		yield fetchData(getLink);
	} catch (error) {
		yield put({ type: SIDEBAR_UPSERT_ERROR, error });

		globalHandleError({
			module: 'sidebar',
			subModule: 'doResendInvitations',
			error,
		});
	}
}

function* doGetInvitationLink({ id }: { id: string }) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'inviteLink',
			apiUrlParam: id,
		});

		const response: IApiSchema['MemberInviteUrl'] = yield fetchData(request);

		yield showError(response);

		yield put({
			type: GET_INVITATION_LINK_SUCCESS,
		});

		const textToCopy = response.url.replace('http://localhost:3000', window.location.origin);
		setTimeout(() => {
			try {
				navigator.clipboard.writeText(textToCopy);
			} catch (err) {
				// empty error
			}
		});

		yield put(
			popupAlertShow({
				contentKey: 'invitationLinkCopied',
				type: 'success',
				withCloseButton: true,
				iconName: 'copy',
			}),
		);
	} catch (error) {
		yield put({ type: GET_INVITATION_LINK_ERROR, error });

		globalHandleError({
			module: 'sidebar',
			subModule: 'doGetInvitationLink',
			error,
		});
	}
}

function* doUpdateCurrentPlan({
	payload: {
		customer_id, plan_id, expires_at, start_at, billing_period, status, payment_type,
	},
}: {
	payload: {
		customer_id: number;
		plan_id: number;
		expires_at: string;
		start_at: string;
		billing_period: string;
		status: string;
		payment_type: string;
	}
}) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'updateCurrentPlan',
			requestBody: {
				customer_id,
				plan_id,
				expire_at: expires_at,
				billing_period,
				start_at,
				status,
				payment_type,
			},
		});

		const response: IApiSchema['PlanSubscriptionDetailed'] = yield fetchData(request);

		yield showError(response);

		const companies: IApiSchema['CustomerBrief'][] = yield select(selectMainData('customers'));
		const company = companies.find((company) => company.id === customer_id);

		const plans: {
			id: number,
			titles: string,
			prices: string,
		}[] = yield select(selectMainData('subscriptionPlansByCustomer'));
		const newPlan = plans.find((plan) => plan.id === plan_id);

		const newData = {
			subscription_expire_at: response.expire_at,
			payment_type: response.type,
			plan_id: response.plan_id,
			limits: response.limits,
			counts: response.counts,
			plan_titles: newPlan?.titles,
			plan_prices: newPlan?.prices,
		};

		if (company) {
			// @ts-ignore
			yield doUpdateTableData('customers', { ...company, ...newData });
		}

		const sidebarDetails: { status: string } = yield select(selectSidebarDetails);
		yield put({
			type: SIDEBAR_UPSERT_SUCCESS,
			item: { ...sidebarDetails, ...newData },
		});

		yield put(
			popupAlertShow({
				contentKey: 'dataSuccessfullySaved',
				type: 'success',
				withCloseButton: true,
				iconName: 'statusActive',
			}),
		);
	} catch (error) {
		yield put({ type: UPDATE_CURRENT_PLAN_ERROR });

		globalHandleError({
			module: 'sidebar',
			subModule: 'doUpdateCurrentPlan',
			error,
		});
	}
}

export default function* sagas() {
	return [
		// @ts-ignore
		yield takeLatest(GET_TABLE_ITEM_DETAILS, doGetTableItemDetails),
		// @ts-ignore
		yield takeLatest(SIDEBAR_UPSERT, doUpsertSidebar),
		// @ts-ignore
		yield takeLatest(INVITATIONS_SEND, doSendInvitations),
		// @ts-ignore
		yield takeLatest(GET_INVITATION_LINK, doGetInvitationLink),
		// @ts-ignore
		yield takeLatest(INVITATIONS_RESEND, doResendInvitations),
		// @ts-ignore
		yield takeLatest(DELETE_ENTITY, doDeleteEntity),
		// @ts-ignore
		yield takeLatest(UPDATE_CURRENT_PLAN, doUpdateCurrentPlan),
	];
}