import styled from 'styled-components';
import FlexRow from '../../atomic-design-components/atoms/FlexRow/FlexRow';
import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import { convertHexToRGBA } from '../../utils/convertHexToRgba';

export const StyledCardContent = styled.div`
  border: 1px solid ${({ theme: { color } }) => color.general.lighter};
  border-radius: 3px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &.active:not(.disabled) {
    border: 1px solid ${({ theme }) => theme.color.status.success};
    outline: 2px solid ${({ theme }) => `${theme.color.status.success}40`};

    label .typography {
      color: ${({ theme }) => theme.color.status.success} !important;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  .switchableCardItemRow {
    padding: 15px;

    &:not(:last-child, :first-child) {
      padding-bottom: 0;
    }

    &.live_chat,
    &.callback {

      .active {
        color: ${({ theme: { color } }) => color.status.success};
      }
    }

    &.connectButton {
      cursor: pointer;
    }

    &.live_chat .active {
      color: ${({ theme: { color } }) => color.status.success};
    }
  }

  .tooltip {
    right: 0;
  }

  .switch {
    order: 1;
  }

  label {
    gap: 10px;
  }

  .inputContainer {
    flex-grow: 1;
  }
`;
export const StyledLine = styled.hr`
  height: 1px;
  margin: 0;
  border-width: 0;
  background-color: ${({ theme }) => theme.color.general.lighter};
  color: ${({ theme }) => theme.color.general.lighter};
`;

export const StyledWidgetCustomize = styled(FlexRow)`
  align-items: flex-start;
  width: 100%;
  padding-top: 10%;

  @media only screen and (width <= 900px) {
    margin-bottom: 90px !important;
    background-image: none;
  }
`;

export const StyledWidgetCustomizedContainer = styled.div`
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 320px;
  max-width: 370px;
  margin: 0 auto;
  flex-grow: 1;

  .form {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.color.general.white};
    box-shadow: 0 14px 15px rgb(0 0 0 / 15%);

    &.callbackForm {
      margin-bottom: 50px;
    }
  }
`;

export const StyledPreviewIcon = styled(Icon)`
  position: absolute;
  right: 0;
  bottom: -86px;
  left: auto;
  padding-bottom: 20px;
  animation: pulse 1.5s infinite;

  &.right_right {
    right: 0;
    left: auto;

    &.withMargin.callback_button {
      right: 76px;
    }
  }

  &.left_left {
    right: auto;
    left: 0;

    &.withMargin.chat_button {
      left: 76px;
    }
  }

  &.left_right {

    &.chat_button {
      right: auto;
      left: 0;
    }

    &.callback_button {
      right: 0;
      left: auto;
    }
  }

  &.right_left {

    &.callback_button {
      right: auto;
      left: 0;
    }

    &.chat_button {
      right: 0;
      left: auto;
    }
  }

  &:hover {
    animation: none;
  }

  @keyframes pulse {

    0% {
      transform: scale(0.75);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.75);
    }
  }
`;

export const StyledAutoMessagesContainer = styled.div`
  position: relative;
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => `${convertHexToRGBA(theme.color.general.white, 0.5)}`};

    .loader {
      position: sticky;
      top: calc(50% - 16px);
      left: calc(50% - 16px);
    }
  }
`;

export const StyledPanel = styled.div`
  display: flex;
  flex-direction: column;

  .headerChevronIcon {
    margin: 0 0 0 10px;
  }

  .expansionPanel {
    padding: 20px;
  }

  .expansionPanel:not(:first-child) {
    padding-top: 20px;
    border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
  }
`;